import { useState, useEffect } from 'react';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { genderNeutral } from '../utils/genderNeutral';
import { db } from '../firebase';

export function useBodyAreas() {
  const [bodyAreas, setBodyAreas] = useState([]);

  const FIREBASE_COLLECTION = process.env.REACT_APP_FIREBASE_COLLECTION;
  const collectionRef = collection(db, FIREBASE_COLLECTION);
  const q = query(collectionRef, orderBy('index'));

  useEffect(() => {
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        let fetchedData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const filtered = fetchedData.filter(
          (elem) => !elem.id.includes('upgrades') && elem.id !== 'bundles'
        );
        // Remove all areas with female first
        filtered.forEach(
          (doc) =>
            (doc.areas = doc.areas.filter(
              (area) => !area.id.includes('female')
            ))
        );
        // Then Gender Neutral the male ones
        filtered.forEach((doc) =>
          doc.areas.forEach((area) => {
            area.id = genderNeutral(area.id);
            area.name = genderNeutral(area.name);
          })
        );
        filtered.forEach((doc) =>
          doc.areas.sort((a, b) => a.name.localeCompare(b.name))
        );

        filtered.forEach((doc) => {
          const areas = [...doc.areas];
          doc.areas = areas.filter((area) => area.display);
        });

        filtered.forEach((doc) => doc.areas.sort((a, b) => a.index - b.index)); // if there's an index
        filtered.push({
          id: 'full_body',
          name: 'Full Body',
          index: filtered.length,
          areas: [
            {
              id: 'full_body',
              name: 'Full Body',
              index: 0,
              size: 'BODY',
            },
          ],
        });
        setBodyAreas(filtered);
      },
      (error) => {
        console.error(error);
      }
    );
    // Stop listening to changes
    return unsubscribe;
  }, []);
  return bodyAreas;
}
export default useBodyAreas;

// import { doc, updateDoc } from "firebase/firestore"
// Outside the useffect
// const addNewPropToAreas = async (parent) => {
//   let areasArr = [...parent.areas]
//   areasArr.forEach(area => {
//     area.size = sizes[area.name]
//   })
//   console.log(areasArr)
//   const docRef = doc(db, FIREBASE_COLLECTION, parent.id)
//   updateDoc(docRef, { areas: areasArr })
// }

// Inside the useEffect
// addNewPropToAreas(fetchedData[6])
