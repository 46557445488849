import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import MILAN_LOGO from '../images/logo.webp';
import TTWrapper from './TTWrapper';

function TreatmentTimeHome() {
  const [passcode, setPasscode] = useState('');
  const [isValid, setIsValid] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (passcode.length === 4) {
      setLoading(true);
      axios
        .get(`/.netlify/functions/verifyPassCode?passcode=${passcode}`)
        .then((res) => {
          setIsValid(res.data.valid);
          if (!res.data.valid) {
            setError(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setError('Something went wrong :(');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError('');
      setIsValid(null);
      setLoading(false);
    }
  }, [passcode]);

  return (
    <main id='tx-time-app' className='milan-bg-light-grey pb-3'>
      <section id='treatment-time'>
        {isValid ? (
          <TTWrapper /> // Show the LockScreen
        ) : (
          <div className='container pt-5'>
            <div className='row d-flex justify-content-center align-items-center py-lg-5'>
              <div className='col'>
                <Form
                  className='card border-0 shadow-sm py-5 w-100 mx-auto'
                  style={{ maxWidth: '500px' }}
                >
                  <figure className='text-center'>
                    <img
                      src={MILAN_LOGO}
                      alt='Milan Logo Blue'
                      style={{ maxWidth: '140px' }}
                    />
                  </figure>
                  <h1 className='h4 px-3 text-center milan-text-primary fw-bold'>
                    Treatment Time Calculator
                  </h1>
                  {loading ? (
                    <h5 className='fw-normal my-3 text-center milan-text-primary'>
                      Verifying...
                    </h5>
                  ) : (
                    <>
                      <h5 className='fw-normal my-3 text-center milan-text-primary'>
                        Enter the Passcode
                      </h5>
                      <Form.Group
                        className='form-outline text-center mx-auto'
                        controlId='password'
                        style={{ maxWidth: '140px' }}
                      >
                        <Form.Control
                          className={`form-control-lg text-center ${
                            error ? 'border-danger text-danger mb-2' : 'mb-4'
                          }`}
                          type='password'
                          placeholder='****'
                          maxLength='4'
                          value={passcode}
                          onChange={(e) => setPasscode(e.target.value)}
                        />
                        {error && (
                          <small className='text-danger'>
                            Invalid Passcode
                          </small>
                        )}
                      </Form.Group>
                    </>
                  )}
                </Form>
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </main>
  );
}

export default TreatmentTimeHome;
